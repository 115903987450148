<template>
  <div class="vip">
    <nav-header ref="headers" />
    <div class="vip-content">
      <h3 class="vip-title">
        <div class="vip-title-bg">
          <img src="../../assets/img/title_bg/buy.png" alt />
        </div>
        <div class="vip-title-name">购买/续费个人大会员</div>
        <div class="vip-title-line" />
      </h3>
      <div class="vip-wrap">
        <!-- 会员头部 -->
        <div class="vip-head">
          <div class="vip-user-header">
            <img :src="head" alt />
          </div>
          <div class="vip-tips">
            选择会员套餐（购买后不支持7天无理由退货。若充值不成功，请联系客服邮箱：dannapdf@163.com）
          </div>
        </div>
        <div class="vip-product">
          <!-- 商品列表 -->
          <div class="vip-item-wrap">
            <div
              v-for="(item, index) in list"
              :key="index"
              class="vip-item"
              :class="item.itemId === isActive ? 'active' : ''"
              @click="productClick(item, true)"
            >
              <div
                class="vip-price"
                :class="item.itemId === isActive ? 'active-text' : ''"
              >
                <span>¥</span>
                <span class="vip-price-all">{{ item.showP }}</span>
                <span
                  >/{{
                    item.validTimeDayShow === "1"
                      ? "永久"
                      : item.validTimeDayShow
                  }}</span
                >
              </div>
              <div
                class="vip-stage"
                :class="item.itemId === isActive ? 'active-text' : ''"
              >
                {{ item.showDesc }}
              </div>
              <div v-if="item.inRecommend" class="vip-recommend">推荐</div>
              <div v-if="item.itemId === isActive" class="vip-check" />
            </div>
          </div>
          <!-- 会员权益 -->
          <div class="vip-rights">
            <div class="vip-rights-left">
              <div class="vip-icon">
                <img src="../../assets/img/vip/vip-icon1.png" alt />
              </div>
              <div class="vip-rights-title">会员特权：</div>
            </div>
            <div
              v-for="(item, index) in rights"
              :key="index"
              class="vip-rights-item"
            >
              <div class="vip-rights-check">
                <img src="../../assets/img/vip/has.png" alt />
              </div>
              <div class="vip-rights-name">{{ item }}</div>
            </div>
          </div>
          <!-- 支付方式 -->
          <div class="pay-mode">
            <div class="pay-title">支付方式：</div>
            <div
              v-for="item in pay"
              :key="item.id"
              class="pay-btn"
              :class="item.id === payActive ? 'pay-active' : ''"
              @click="payClick(item)"
            >
              <div class="wx-icon">
                <img :src="item.icon" alt />
              </div>
              <div class="wx-text">{{ item.name }}</div>
            </div>
          </div>
          <!-- 购买时长 -->
          <div class="pay-mode">
            <div class="pay-title">购买时长：</div>
            <div class="pay-time">{{ payTime }}</div>
          </div>
          <!-- 支付金额 -->
          <div class="pay-mode pay-mode-money" style>
            <div class="pay-title pay-money-title">支付金额：</div>
            <div class="pay-money">
              <span class="pay-i">¥</span>
              <span class="pay-m">{{ payMoney }}</span>
              <span class="pay-s">{{ discount }}</span>
            </div>
          </div>
          <!-- 立即支付 -->
          <div class="pay-now">
            <el-button
              class="pay-now-btn"
              :loading="payBtnLoading"
              :class="checked ? '' : 'disabled'"
              @click="payNow"
            >
              立即支付
            </el-button>
            <div class="pay-check">
              <el-checkbox v-model="checked" class="pay-check-checkbox" />
              <span>
                <span>同意并接受</span>
                <span class="pay-vip-server" @click="toVipServe"
                  >《会员服务条款》</span
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <copy-right-footer />
    <div class="pay-dialog" v-if="showPayDialog">
      <div class="dialog-content">
        <div class="dialog-close">
          <img
            @click="closeDialog"
            class="close"
            src="../../assets/img/home/close.png"
            alt=""
          />
        </div>
        <div class="dialog-pay-content">
          <div class="dialog-pay-title">
            <span class="t1">支付金额：</span>
            <span class="t2">{{ payMoney }}</span>
            <span class="t3">元</span>
          </div>
          <div class="dialog-pay-desc">
            <span>购买物品：{{ payDesc }}</span>
            <span v-if="showVipTime">( {{ vipTime }} )</span>
          </div>
          <div
            class="dialog-pay-box"
            :class="payActive === 'wxpay' ? '' : 'ali-border'"
          >
            <div v-if="payActive === 'wxpay'" id="qrcode"></div>
            <div v-if="payActive === 'alipay'" id="alipayDiv"></div>
            <div class="pay-box-loading" v-if="payCodeLoading">
              <div class="loading-content">
                <i
                  class="mask-icon el-icon-loading"
                  :class="payActive === 'wxpay' ? '' : 'ali-loading'"
                ></i>
                <div
                  class="mask-text"
                  :class="payActive === 'wxpay' ? '' : 'ali-loading'"
                >
                  正在加载...
                </div>
              </div>
            </div>
            <!-- <div class="code-refresh" @click="refreshCode" v-if="showRefresh"> -->
            <div class="code-refresh" v-if="showRefresh">
              <div class="code-details">
                <!-- <i class="el-icon-refresh-right code-details-icon"></i> -->
                <div>二维码已失效</div>
                <div>请重试</div>
              </div>
              <!-- <div></div> -->
            </div>
          </div>
          <div class="dialog-pay-type">
            <div v-if="payActive === 'wxpay'" class="pay-type-icon">
              <img :src="pay[0].icon" alt="" />
            </div>
            <div v-else class="pay-type-icon">
              <img :src="pay[1].icon" alt="" />
            </div>
            <div v-if="payActive === 'wxpay'" class="pay-type-desc">
              {{ pay[0].name }}
            </div>
            <div v-else class="pay-type-desc">
              {{ pay[1].name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "../../components/NavHeader";
import CopyRightFooter from "../../components/CopyRightFooter";
import { hxsGet } from "../../apis/index";
import QRCode from "qrcodejs2";
export default {
  components: {
    NavHeader,
    CopyRightFooter
  },
  data() {
    return {
      srcdoc: "",
      showRefresh: false,
      payBtnLoading: false,
      showPayDialog: false,
      pid: "",
      discount: "",
      isActive: "",
      payActive: "wxpay",
      payTime: "",
      payMoney: "",
      payDesc: "",
      list: [],
      rights: [
        "PDF与各格式互转",
        "CAD转PDF",
        "OFD转PDF",
        "文档转换次数无上限",
        "下载文档大小不限",
        "云盘保存30天"
      ],
      pay: [
        {
          id: "wxpay",
          name: "微信支付",
          icon: require("../../assets/img/vip/wx.png")
        },
        {
          id: "alipay",
          name: "支付宝支付",
          icon: require("../../assets/img/vip/ali.png")
        }
      ],
      checked: true,
      productCode: "zhuanhuan",
      checkProduct: {},
      entranceId: "500004_pdf_001",
      orderId: "",
      timer: null,
      vipTimer: null,
      payCodeLoading: true,
      vipTime: "",
      exTime: "",
      showVipTime: false,
      head:
        localStorage.getItem("head") ||
        require("../../assets/img/default-head.png")
    };
  },
  created() {
    this.pid = this.$route.query.pid;
    this.isActive = this.$route.query.pid;
    this.getPordList();
  },
  watch: {
    showPayDialog(val, oldVal) {
      // 弹窗打开
      if (val) {
        // 获取商品时长
        const prodT = +this.exTime * 24 * 60 * 60 * 1000;
        let vipT = "";
        // 获取会员状态
        const viper = localStorage.getItem("isVip");

        // 判断是否是会员，如果是会员，取会员时间，如果不是从当前时间算起
        if (JSON.parse(viper)) {
          // 获取会员时长
          vipT = localStorage.getItem("vipDate");
        } else {
          // 获取当前时间戳
          vipT = new Date().getTime();
        }
        // 用户会员有效期 + 商品时长 = 充值后的会员到期时间
        const tTime = +vipT + prodT;
        // 拼串： 当前用户会员到期日期 - 充值后的到期日期
        const str = this.formatDate(vipT) + "-" + this.formatDate(tTime);

        this.vipTime = str;
      } else {
        this.showRefresh = false;
      }
    }
  },
  methods: {
    formatDate(value) {
      if (typeof value === "undefined") {
        return "";
      } else {
        const date = new Date(parseInt(value));
        const y = date.getFullYear();
        let MM = date.getMonth() + 1;

        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();

        d = d < 10 ? "0" + d : d;

        return y + "/" + MM + "/" + d;
      }
    },
    // 获取商品列表
    getPordList() {
      hxsGet(
        "doconline/open/pay/grade/option/list.html?qid=" + localStorage.getItem("qid")
      )
        .then((res) => {
          // 设置默认选中商品
          res.data.forEach((item) => {
            if (item.itemId === parseInt(this.pid)) {
              this.productClick(item);
            }
          });
          // 赋值商品列表;
          this.list = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 商品切换
    productClick(item, kg) {
      if (window._czc) {
        window._czc.push([
          "_trackEvent",
          "DFP在线转换",
          "会员购买",
          "套餐选择点击次数"
        ]);
      }
      // 存储信息
      this.checkProduct = item;
      this.isActive = item.itemId;
      this.discount = item.desc;
      this.payTime =
        item.validTimeDayShow === "1" ? "永久会员" : item.validTimeDayShow;
      this.payMoney = item.showP;
      this.payDesc = item.description;
      this.exTime = item.validTimeDay;
      if (kg) {
        const routeUrl = this.$router.resolve({
          query: {
            pid: item.itemId
          }
        });

        window.location.href = window.location.origin + "/" + routeUrl.href;
      }
    },
    // 支付方式切换
    payClick(item) {
      this.payActive = item.id;
    },
    // 立即支付 - 创建订单
    payNow() {
      if (window._czc) {
        window._czc.push([
          "_trackEvent",
          "DFP在线转换",
          "会员购买",
          "立即支付点击次数"
        ]);
      }
      // 判断是否禁用，如果禁用return，未禁用，下一步
      if (this.checked) {
        const loginInfo = localStorage.getItem("isLogin");

        // 判断是否登录，如果登录创建订单， 未登录则弹出登录弹窗
        if (loginInfo) {
          this.payBtnLoading = true;
          if (this.checkProduct.validTimeDay === "1") {
            this.showVipTime = false;
          } else {
            this.showVipTime = true;
          }
          this.getPayCode();
        } else {
          this.$refs.headers.OpenWxWebLogin();
        }
      } else {
        return;
      }
    },
    // 获取支付二维码
    getPayCode() {
      hxsGet(
        "doconline/pay/unifyorder.html?payType=" +
          this.payActive +
          "&token=" +
          localStorage.getItem("token") +
          "&gradeid=" +
          this.isActive +
          "&qid=" +
          localStorage.getItem("qid")
      )
        .then((res) => {
          this.payBtnLoading = false;
          this.timer = setInterval(() => {
            this.getPayStatus(res.data.id);
          }, 2000);
          // 页面创建二维码
          this.showPayDialog = true;
          this.$nextTick(() => {
            new QRCode(this.payActive === "wxpay" ? "qrcode" : "alipayDiv", {
              width: 200,
              height: 200,
              text: res.data.codeUrl
            });
            this.payCodeLoading = false;
          });
        })
        .catch((err) => {
          this.payBtnLoading = false;
          console.log(err);
        });
    },
    // 获取支付状态
    getPayStatus(i) {
      const type = this.payActive === "wxpay" ? 0 : 1;

      hxsGet(
        "doconline/order/pay/status.html?token=" +
          localStorage.getItem("token") +
          "&orderId=" +
          i +
          "&type=" +
          type
      ).then((res) => {
        if (res.data) {
          this.$message({
            message: "支付成功！",
            type: "success"
          });
          clearInterval(this.timer);
          this.vipTimer = setTimeout(() => {
            this.$refs.headers.getUserVip();
            clearTimeout(this.vipTimer);
            this.$router.push("/");
          }, 2000);
          this.showPayDialog = false;
          if (window._czc) {
            if (this.payActive === "wxpay") {
              window._czc.push([
                "_trackEvent",
                "DFP在线转换",
                "会员购买",
                "支付成功-微信支付"
              ]);
              window._czc.push([
                "_trackEvent",
                "DFP在线转换",
                "会员购买",
                "支付方式-微信支付"
              ]);
            } else {
              window._czc.push([
                "_trackEvent",
                "DFP在线转换",
                "会员购买",
                "支付成功-支付宝支付"
              ]);
              window._czc.push([
                "_trackEvent",
                "DFP在线转换",
                "会员购买",
                "支付方式-支付宝支付"
              ]);
            }
          }
        }
        // else if (res.data.status === 7) {
        //   this.showRefresh = true;
        //   // this.$message({
        //   //   message: '取消支付！',
        //   //   type: 'error'
        //   // });
        //   clearInterval(this.timer);
        //   if (window._czc) {
        //     if (this.payActive === "wxpay") {
        //       window._czc.push([
        //         "_trackEvent",
        //         "DFP在线转换",
        //         "会员购买",
        //         "支付失败-微信支付"
        //       ]);
        //       window._czc.push([
        //         "_trackEvent",
        //         "DFP在线转换",
        //         "会员购买",
        //         "支付方式-微信支付"
        //       ]);
        //     } else {
        //       window._czc.push([
        //         "_trackEvent",
        //         "DFP在线转换",
        //         "会员购买",
        //         "支付失败-支付宝支付"
        //       ]);
        //       window._czc.push([
        //         "_trackEvent",
        //         "DFP在线转换",
        //         "会员购买",
        //         "支付方式-支付宝支付"
        //       ]);
        //     }
        //   }
        // } else {
        //   if (window._czc) {
        //     if (this.payActive === "wxpay") {
        //       window._czc.push([
        //         "_trackEvent",
        //         "DFP在线转换",
        //         "会员购买",
        //         "支付失败-微信支付"
        //       ]);
        //       window._czc.push([
        //         "_trackEvent",
        //         "DFP在线转换",
        //         "会员购买",
        //         "支付方式-微信支付"
        //       ]);
        //     } else {
        //       window._czc.push([
        //         "_trackEvent",
        //         "DFP在线转换",
        //         "会员购买",
        //         "支付失败-支付宝支付"
        //       ]);
        //       window._czc.push([
        //         "_trackEvent",
        //         "DFP在线转换",
        //         "会员购买",
        //         "支付方式-支付宝支付"
        //       ]);
        //     }
        //   }
        // }
      });
      // .catch((err) => {
      //   console.log(err);
      // });
    },
    // 关闭支付弹窗
    closeDialog() {
      this.showPayDialog = false;
      this.payCodeLoading = true;
      clearInterval(this.timer);
    },
    // 跳转会员服务
    toVipServe() {
      this.$router.push("/vipserver");
    }
  }
};
</script>

<style lang="scss" scoped>
#alipayFrame {
  width: 200px;
  height: 200px;
  display: block;
  margin: 13px auto;
}
.el-checkbox__inner {
  background-color: #fff !important;
  border-color: #dededf !important;
}
.disabled {
  background: #ccc !important;
  cursor: no-drop !important;
}
.active {
  border: 2px solid #0078f8 !important;
  box-shadow: 0px 6px 20px 0px rgba(0, 120, 248, 0.2);
}
.active-text {
  color: #0078f8 !important;
}
.pay-active {
  border-color: #0078f8 !important;
  background-color: #eef5ff !important;
}
.ali-border {
  width: 226px !important;
  height: 226px !important;
  border-color: #009fe8 !important;
}
.ali-loading {
  color: #009fe8 !important;
}
.vip {
  position: relative;
  width: 100%;
  height: 100%;
  .vip-content {
    padding-top: 55px;
    box-sizing: border-box;
    .vip-title {
      text-align: center;
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #212121;
      position: relative;
      .vip-title-name {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
      }
      .vip-title-bg {
        width: 500px;
        height: 30px;
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }
      .vip-title-line {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 44px;
        height: 6px;
        background: #0078f8;
        border-radius: 3px;
        bottom: -55px;
      }
    }
    .vip-wrap {
      width: 1200px;
      min-width: 1200px;
      box-sizing: border-box;
      margin: 85px auto 70px auto;
      border: 1px solid #e7eaf1;
      border-radius: 6px;
      overflow: hidden;
      .vip-head {
        height: 57px;
        background: url("../../assets/img/vip/vip-head.png") no-repeat;
        background-size: 100% 100%;
        padding-left: 25px;
        display: flex;
        align-items: center;
        .vip-user-header {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 13px;
        }
        .vip-tips {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #fffefe;
        }
      }
      .vip-product {
        // height: 585px;
        padding: 38px 60px 48px 60px;
        box-sizing: border-box;
        .vip-item-wrap {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          .vip-item {
            position: relative;
            width: 240px;
            height: 130px;
            padding: 0 10px;
            border: 2px solid #e7eaf1;
            border-radius: 6px;
            box-sizing: border-box;
            cursor: pointer;
            margin-right: 40px;
            .vip-price {
              height: 82px;
              line-height: 82px;
              text-align: center;
              border-bottom: 1px dashed #e7eaf1;
              box-sizing: border-box;
              color: #444444;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              .vip-price-all {
                font-size: 40px;
                font-weight: bold;
              }
            }
            .vip-stage {
              height: 47px;
              line-height: 47px;
              text-align: center;
              box-sizing: border-box;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              // color: #037af8;
              color: #999999;
            }
            .vip-recommend {
              position: absolute;
              top: -8px;
              left: -2px;
              width: 48px;
              height: 28px;
              background: linear-gradient(135deg, #ff4216 0%, #ff6f1d 100%);
              border-radius: 6px 6px 6px 0px;
              text-align: center;
              line-height: 28px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
            }
            .vip-check {
              position: absolute;
              bottom: 0;
              right: 0;
              width: 30px;
              height: 30px;
              background: url("../../assets/img/vip/check.png") no-repeat;
              background-size: 100% 100%;
            }
          }
          .vip-item:last-child {
            margin: 0;
          }
        }
        .vip-rights {
          width: 1080px;
          height: 39px;
          background: #f5f5f5;
          border: 1px dashed #cccccc;
          border-radius: 6px;
          margin-top: 37px;
          display: flex;
          align-items: center;
          padding-left: 11px;
          box-sizing: border-box;
          .vip-rights-left {
            display: flex;
            height: 20px;
            .vip-icon {
              width: 19px;
              height: 16px;
              margin-right: 5px;
            }
            .vip-rights-title {
              font-size: 13px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #666666;
              margin-right: 20px;
            }
          }
          .vip-rights-item {
            display: flex;
            align-items: center;
            margin-right: 30px;
            .vip-rights-check {
              width: 13px;
              height: 9px;
            }
            .vip-rights-name {
              font-size: 13px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              margin-left: 6px;
            }
          }
        }
        .pay-mode {
          margin-top: 40px;
          display: flex;
          align-items: center;
          .pay-title {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            margin-right: 14px;
          }
          .pay-money-title {
            position: relative;
            top: -6px;
          }
          .pay-btn {
            width: 140px;
            height: 44px;
            margin-right: 20px;
            background: #ffffff;
            border: 1px solid #e7eaf1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
            .wx-icon {
              width: 30px;
              height: 30px;
              margin-right: 8px;
            }
          }
          .pay-btn:hover {
            border-color: #0078f8 !important;
            background-color: #eef5ff !important;
          }
          .pay-time {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          .pay-money {
            font-family: Microsoft YaHei;
            .pay-i {
              font-size: 22px;
              color: #ff3f13;
              font-weight: 400;
            }
            .pay-m {
              font-size: 32px;
              color: #ff3f13;
              font-weight: bold;
            }
            .pay-s {
              font-size: 14px;
              color: #999999;
              font-weight: 400;
              margin-left: 10px;
            }
          }
        }
        .pay-mode-money {
          align-items: flex-end;
          // vertical-align: bottom;
        }
        .pay-now {
          display: flex;
          align-items: center;
          margin-top: 30px;
          .pay-now-btn {
            width: 250px;
            height: 46px;
            background: #0078f8;
            border-radius: 6px;
            text-align: center;
            // line-height: 46px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
            border: none;
          }
          .pay-now-btn:hover {
            background: #268cf9;
          }
          .pay-check {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            .pay-check-checkbox {
              margin: 0 8px 0 28px;
            }
            .pay-vip-server {
              cursor: pointer;
            }
            .pay-vip-server:hover {
              color: #0078f8;
            }
          }
        }
      }
    }
  }
  .pay-dialog {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 101;
    .dialog-content {
      width: 540px;
      height: 480px;
      background: #ffffff;
      border-radius: 6px;
      position: fixed;
      left: 50%;
      top: calc(50% - 250px);
      margin-left: -270px;
      box-sizing: border-box;
      .dialog-close {
        height: 50px;
        display: flex;
        align-items: center;
        padding-right: 10px;
        box-sizing: border-box;
        .close {
          width: 32px;
          height: 32px;
          margin-left: auto;
          cursor: pointer;
        }
      }
      .dialog-pay-content {
        .dialog-pay-title {
          text-align: center;
          .t1 {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          .t2 {
            font-size: 32px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ff3f13;
          }
          .t3 {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
        }
        .dialog-pay-desc {
          margin-top: 15px;
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
        .dialog-pay-box {
          margin: 30px auto 17px auto;
          box-sizing: border-box;
          width: 226px;
          height: 226px;
          background: #ffffff;
          border: 1px solid #31ad37;
          border-radius: 6px;
          position: relative;
          #qrcode {
            width: 200px;
            height: 200px;
            margin: 13px auto;
            background-color: red;
          }
          #alipayDiv {
            width: 200px;
            height: 200px;
            margin: 13px auto;
            background-color: #009fe8 !important;
          }
          .pay-box-loading {
            width: 100%;
            height: 100%;
            border-radius: 6px;
            background: #fff;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 9999;
            .loading-content {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              text-align: center;
              .mask-icon {
                color: #31ad37;
                font-size: 24px;
              }
              .mask-text {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #31ad37;
                margin-top: 10px;
              }
            }
          }
          .code-refresh {
            width: 99%;
            height: 99%;
            border-radius: 6px;
            background: rgba(0, 0, 0, 0.7);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 9999;
            text-align: center;
            font-size: 14px;
            color: #fff;
            .code-details {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              .code-details-icon {
                font-size: 48px;
                margin-bottom: 10px;
                color: #fff;
              }
            }
          }
        }
        .dialog-pay-type {
          display: flex;
          align-items: center;
          justify-content: center;
          .pay-type-icon {
            width: 30px;
            height: 30px;
          }
          .pay-type-desc {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #555555;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>
