<template>
  <div class="footer">
    <div class="footer-top">
      <div class="footer-content">
        <div class="convert-link">
          <span>热门功能：</span>
          <router-link
            v-for="item in menuList"
            :key="item.id"
            class="links"
            @click="toConvert(item.href)"
            :to="{ path: item.href }"
          >
            {{ item.name }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="footer-content">
        <div class="company">
          <span class="r-link" @click="toPrivacy(1)">隐私政策</span>
          <div class="line" />
          <span class="r-link" @click="toPrivacy(2)">用户协议</span>
          <div class="line" />
          <span>上海琴傲信息技术服务有限公司</span>
          <div class="line" />
          <span class="r-link" @click="toReview">沪ICP备2021016433号-1</span>
        </div>
        <div class="company">
          <span>地址：中国（上海）自由贸易试验区张江路665号三层</span>
          <div class="line" />
          <span>电话: 021-68620255</span>
        </div>

        <div class="company">
          <span>lanshan.com All Right Reserved. 版权所有</span>
          <div class="line" />
          <span class="r-link" @click="toReview">沪ICP备2021016433号-1</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { menuList } from "../utils/menu";
export default {
  data() {
    return {
      menuList
    };
  },
  methods: {
    // 跳转转换页
    toConvert(i) {
      if (window._czc) {
        const str = "底部热门功能" + i + "点击次数";

        window._czc.push([
          "_trackEvent",
          "DFP在线转换",
          "使用转换功能",
          str
        ]);
      }
    },
    // 跳转隐私政策/用户协议
    toPrivacy(i) {
      i === 1 ? this.$router.push("/privacy") : this.$router.push("/licence");
    },
    // 跳转备案网
    toReview() {
      window.open("https://beian.miit.gov.cn/", "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 200px;
  background-color: #282828;
  .footer-top {
    width: 100%;
    height: 80px;
    border-bottom: 1px solid #333333;
    .footer-content {
      width: 1200px;
      min-width: 1200px;
      height: 80px;
      margin: 0 auto;
      .convert-link {
        font-size: 14px;
        font-weight: 400;
        color: #777777;
        line-height: 80px;
        .links {
          padding: 0 17px;
          border-right: 1px solid #777777;
          cursor: pointer;
          color: #777777;
        }
        .links:hover {
          color: #999999;
        }
        .links:last-child {
          border-right: none;
        }
      }
    }
  }
  .footer-bottom {
    width: 100%;
    height: 118px;
    .footer-content {
      width: 1200px;
      min-width: 1200px;
      height: 118px;
      margin: 0 auto;
      padding-top: 38px;
      box-sizing: border-box;
      >.company:nth-child(2),>.company:nth-child(3){
        display: none;
      }
      .company {
        font-size: 14px;
        font-weight: 400;
        color: #777777;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        .line {
          height: 15px;
          width: 1px;
          background: #777777;
          margin: 0 20px;
        }
        .r-link {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
